.OrdersApp {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.date-pickers {
    margin-top: 3vh;
}

.date-label {
    min-width: 5rem;
    margin: 2px;
}

.date-button {
    background: #fff;
    color: black;
    border: 1px solid black;
    margin: 2px;
}
.date-button:hover {
    background: rgb(255, 238, 238);
    cursor: pointer;
}
.date-button:hover:active {
    background: rgb(255, 176, 176);
}

.center-cell {
    text-align: center; 
    vertical-align: middle;
}

.table-button {
    background: #fff;
    color: black;
    border: 1px solid black;
    border-radius: 20%;
    width: 2rem;
    height: 2rem;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.table-button:hover {
    background: rgb(255, 238, 238);
    cursor: pointer;
}
  
.table-button:hover:active {
    background: rgb(255, 176, 176);
}


.nav-button {
    background: #fff;
    color: black;
    border: 1px solid black;
    border-radius: 20%;
    width: 5vh;
    height: 5vh;
    margin-right: 3vw;
    margin-left: 3vw;
}

.nav-button:hover {
    background: rgb(255, 238, 238);
    cursor: pointer;
}

.nav-button:active {
    background: rgb(255, 238, 238);
}

.wrapper {
    height: 100%;
    width: 100%;
    display: inherit;
    flex-direction: inherit;
    text-align: inherit;
    justify-content: inherit;
  }

.orders-table {
    margin-top: 5px;
    margin-bottom: 2rem;
}

.modal-90w {
    min-width: 90%;
}

.search {
    max-width: 20rem;
    margin: auto;
}

.search-wrapper {
    padding: 0.5rem;
    text-align: left;
}