.breadcrumbs {
  margin: 0;
}

.board {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding-top: 3.5vh;
  padding-bottom: 3.5vh;
  padding-left: 3.5vw;
  padding-right: 3.5vw;
}

.menu-row {
  display: flex;
  flex-direction: row;
}

.wrapper {
  height: 100%;
  width: 100%;
  display: inherit;
  flex-direction: inherit;
  text-align: inherit;
  justify-content: inherit;
}

.settings-button {
  background: #fff;
  color: black;
  border: 1px solid #999;
  font-size: min(1.5rem, 5vw);
  font-weight: bold;
  line-height: 34px;
  height: 7vh;
  min-height: 40px;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 2vh;
  text-align: center;
  width: 29vw;
}

.button {
  background: #fff;
  color: black;
  border: 1px solid #999;
  font-size: min(1rem, 5vh);
}

.download-button {
  background: #fff;
  color: black;
  border: 1px solid #999;
  font-size: min(1rem, 5vw);
  font-weight: bold;
  line-height: 34px;
  height: 5vh;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 2vh;
  text-align: center;
  width: 35vw;
  min-width: 15rem;
  min-height: 2rem;
}

.square {
  background: #fff;
  color: black;
  padding: 0;
  border: 1px solid #999;
  font-size: min(2rem, 5vw);
  font-weight: bold;
  line-height: 110%;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 2vh;
  height: 25vh;
  width: 29vw;
  text-align: center;
}

.square .thumbnail-and-text-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.square .thumbnail-price {
  font-size: min(0.75rem, 2.5vw);
  margin: 0;
  padding-left: 4px;
  padding-bottom: 2px;
  display: inline-block;
  line-height: 0.75rem;
  text-align: left;
  font-weight: normal;
}

.square .thumbnail-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: min(1.5rem, 2.5vw);
  height: 50%;
  line-height: 110%;
}

.square .thumbnail {
  height: 50%;
  object-fit: contain;
  overflow: hidden;
}

.cart-view .thumbnail-and-text-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.cart-view .thumbnail-text {
  display: flex;
  align-items: center;
}

.cart-view .thumbnail {
  height: 100px;
  width: 100px;
  object-fit: contain;
  overflow: hidden;
}

.thumbnail-wrapper {
  height: 100px;
  width: 100px;
}

.button:hover {
  background: rgb(255, 238, 238);
}

.button:hover:active {
  background: rgb(255, 176, 176);
}

th {
  border: 1px solid black;
  height: 5vh;
}

td {
  border: 1px solid black;
  height: 10vh;
  text-align: left;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

td input {
    font-size: 1rem;
    min-width: 65px;
}

textarea {
  width: 100%;
  font-size: 1rem;
  height: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  font-family: sans-serif;
}

.box {
  display: flex;
  width: 100%;
}

table {
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
  border-collapse: collapse;
}

.select {
  display: inline-block;
  min-width: 20rem;
}

form {
  text-align: left;
}

form input {
  min-width: 20rem;
}

form select {
  min-width: 20rem;
}

label {
  height: 20px;
  display:inline-block;
  min-width: 10rem;
}

.ship_date {
  text-align: left;
  margin-bottom: 10px;
}

form textarea {
  max-width: 40rem;
}

.pdf {
  width: 100%;
  width: 900px;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  padding-left: 3.5vw;
  padding-right: 3.5vw;
  text-align: left;
  justify-content: center;
}


.pdf .header {
  display: flex;
}

.pdf .header .header-left {
  flex: 1;
  text-align: left;
}

.pdf .header .header-right {
  flex: 1;
  text-align: right;
}

.pdf td {
  min-width: 70px;
}

.pdf p {
  line-height: 100%;
  margin-bottom: 0.5rem;
}

#name {
  min-width: 300px;
}

#notes {
  min-width: 190px;
}

.policies {
  margin-top: 2rem;
}

.top-alert {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0px;
  left: 0px;
}

.AddProductApp {
  text-align: center;
}

.add-product-button-wrapper {
  width: 100%;
  height: 100%;
  text-align: center;
}

.add-product-button {
  height: max(3rem, min(25vw, 5rem));
  width: max(8rem, min(50vw, 15rem))
}

.vertical-center {
  position: relative;
  top: 40vh;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

nav {
  display: flex;
}

.breadcrumb {
margin: auto;
}